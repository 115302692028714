import React from "react"
import styles from "./Projects.module.scss"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import Project from "./Project"
import PropTypes from 'prop-types';

const getProjects = graphql`
  query {
    allContentfulVyrobky(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          createdAt(formatString: "LLL")
          projectName
          id
          shortDesc
          projectDesc {
            json
          }
          projectType
          mainImg {
            title
            description
            fluid(maxWidth: 350, quality: 60) {
              ...GatsbyContentfulFluid
            }
          }
          slug
        }
      }
    }
  }
`

const OtherProjects = ({ slug }) => {
  const allProjects = useStaticQuery(getProjects).allContentfulVyrobky.edges
  
  // get 5 random projects
  const random = [...allProjects].sort(() => 0.5 - Math.random()).slice(0, 5)
  // filter random projects if it contains current project
  const projectsFiltered = random.filter(project => project.node.slug !== slug)
  // filter projects to 4 if bigger than 4
  const filteredFinal =
    projectsFiltered.length > 4
      ? projectsFiltered.slice(0, 4)
      : projectsFiltered

  return (
    <div className="container">
      <Fade bottom duration={700}>
        <h3 className="mbm">Další projekty</h3>
      </Fade>
      <div className={styles.projects}>
        {filteredFinal.map(project => (
          <Project key={project.node.id} project={project} />
        ))}
      </div>
    </div>
  )
}

export default OtherProjects

OtherProjects.propTypes = {
  slug: PropTypes.string
}