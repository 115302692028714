import React from "react"
import styles from "./Project.module.scss"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from 'prop-types';

const Project = ({ project }) => {
  const { node } = project

  return (
    <Link to={`/reference/${node.slug}`}>
      <div className={styles.project}>
        <div className={styles.imageWrapper}>
          <Img
            className={styles.image}
            fluid={node.mainImg.fluid}
            alt={
              node.mainImg.title
                ? `Projekt: ${node.mainImg.title}`
                : "Reference Kovoart - Umělecké kovářství"
            }
          />
        </div>
        <div className={styles.textWrapper}>
          <h4>{node.projectName}</h4>
        </div>
      </div>
    </Link>
  )
}

export default Project

Project.propTypes = {
  project: PropTypes.object
}