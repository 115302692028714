import React from "react"
import Layout from "../components/layout/Layout"
import { graphql } from "gatsby"
import SecondaryHeader from "../components/sections/SecondaryHeader"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import styles from "./projectDetail.module.scss"
import Gallery from "../components/gallery/Gallery"
import SEO from "../components/SEO"
import Cta from "../components/Cta"
import OtherProjects from "../components/projects/OtherProjects"
import Fade from "react-reveal/Fade"
import Videos from "../components/gallery/Videos"
import seotext from "../constants/seotext"
import PropTypes from 'prop-types';
import useDeviceDetect from "../hooks/useDeviceDetect"

export const query = graphql`
  query($slug: String!) {
    contentfulVyrobky(slug: { eq: $slug }) {
      projectName
      projectDesc {
        json
      }
      seoTitle
      seoDesc
      video {
        file {
          url
        }
      }
      mainImg {
        fluid(maxWidth: 1600, quality: 65) {
          ...GatsbyContentfulFluid
        }
      }
      large: projectImages {
        title
        fixed(width: 2400, quality: 65) {
          ...GatsbyContentfulFixed
          src
          width
          height
        }
      }
      largeMobile: projectImages {
        title
        fixed(width: 1200, quality: 65) {
          ...GatsbyContentfulFixed
          src
          width
          height
        }
      }
      thumbs: projectImages {
        title
        fluid(maxWidth: 500, quality: 65) {
          ...GatsbyContentfulFluid
        }
      }
      slug
      projectType
    }
  }
`

const ProjectsDetail = ({ data, pageContext }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text">{children}</p>
      ),
    },
  }

  const isMobile = useDeviceDetect();

  const {
    projectName,
    projectDesc,
    seoTitle,
    seoDesc,
    mainImg,
    large,
    largeMobile,
    thumbs,
    slug,
    video,
  } = data.contentfulVyrobky


  const largeImages = isMobile ? largeMobile : large;

  return (
    <Layout>
      <SEO
        title={seoTitle ? `${seoTitle} · Reference` : null}
        description={seoDesc ? seoDesc : `${seotext.projekt}`}
        article={true}
      />
      <SecondaryHeader
        pageContext={pageContext}
        disable={[`/reference/${slug}`]}
        crumbLabel={projectName}
        bold={projectName}
        image={mainImg.fluid}
      ></SecondaryHeader>
      <section className="page-section">
        <div className="container">
          {projectDesc && (
            <Fade bottom duration={700}>
              <div className={styles.content}>
                {documentToReactComponents(projectDesc.json, options)}
              </div>
            </Fade>
          )}
          {thumbs && (
            <Fade bottom duration={700}>
              <h3>Galerie projektu</h3>
            </Fade>
          )}
          {thumbs && <Gallery large={largeImages} thumb={thumbs} />}
          {video && <Videos data={video} />}
        </div>
        <OtherProjects slug={slug} />
      </section>
      <Cta small />
    </Layout>
  )
}

export default ProjectsDetail
